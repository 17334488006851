<template>
	<div id="student" v-loading="loading">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">学籍管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="head u-f-item">
				<span class="span">选择条件</span>
				<el-select size="small" v-model="year" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeYear">
					<el-option v-for="item in yearList"  :key="item.id" :label="item.name" :value="item.name"></el-option>
				</el-select>
			</div>
			<div>
				<template v-if="tabIndex==0">
					<div class="content" style="padding: 0 30px">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{total}}条数据，
								<span class="btn" @click="selectAll">选择全部</span>
							</div>
							<div class="u-f-item">
								<el-input placeholder="请输入内容" v-model="sousuo" @change="sousuoList" size="small" style="margin-right: 10px;">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
								<!-- <el-button type="text" icon="el-icon-s-tools" @click="VisibleTap(0)">表头设置</el-button> -->
								<el-button size="small" @click="VisibleTap(1)">新建学籍</el-button>
								<el-button size="small" @click="leaveStu">办理离校</el-button>
								<!-- <el-button size="small">批量重置密码</el-button> -->
								<el-button size="small" @click="openUpdate(1)">批量更新</el-button>
								<el-button size="small" @click="openUpdate(2)">批量录入</el-button>
								<el-button size="small" @click="exportStu">导出</el-button>
							</div>
						</div>
						<el-table
							:data="studentList"
							height="80%"
							ref="multipleTable1"
							tooltip-effect="dark"
							@selection-change="handleSelectionChange"
							style="width: 100%;border: 1px solid #EEEEEE;"
							key="1"
						>
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column label="序号" type="index"  width="70" fixed align="center"></el-table-column>
							<el-table-column prop="ent_year" label="入学学年" fixed align="center"></el-table-column>
							<el-table-column prop="student_num" label="学籍号" fixed align="center"></el-table-column>
							<el-table-column prop="realname" label="姓名" fixed align="center"></el-table-column>
							<el-table-column prop="sex" label="性别" align="center" column-key="sex" :filters="[{text: '男', value: '1'}, {text: '女', value: '2'}]"
							:filter-method="filterHandler">
								<template slot-scope="scope">
									<div>{{scope.row.sex==1?'男':'女'}}</div>
								</template>
							</el-table-column>
							<el-table-column label="数据来源" prop="data_source" column-key="data_source" width="100" :filters="[{text: '新建学籍', value: '1'}, {text: '招生转入', value: '2'}]"
							:filter-method="filterHandler">
								<template slot-scope="scope">
									<div class="btn" style="background: #e4f1ff;color: #1E92FF;">{{scope.row.data_source==1?'新建学籍':'招生转入'}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="idcard" label="身份证"></el-table-column>
							<el-table-column label="学籍性质" prop="stunature_status" column-key="stunature_status" width="100" :filters="[{text: '本校', value: '1'}, {text: '挂学籍', value: '2'}]"
							:filter-method="filterHandler">
								<template slot-scope="scope">
									<div>{{scope.row.stunature_status==1?'本校':'挂学籍'}}</div>
								</template>
							</el-table-column>
							<el-table-column label="状态" prop="status" column-key="status" :filters="[{text: '在校', value: '1'}, {text: '离校', value: '2'}]"
							:filter-method="filterHandler">
								<template slot-scope="scope">
									<div>{{scope.row.status==1?'在校':'离校'}}</div>
								</template>
							</el-table-column>
							<el-table-column label="身份" prop="identity" column-key="identity" :filters="[{text: '群众', value: '1'}, {text: '少先队员', value: '2'}, {text: '团员', value: '3'}, {text: '党员', value: '4'}]"
							:filter-method="filterHandler">
								<template slot-scope="scope">
									<div>
										<blockquote style="margin:0" v-if="scope.row.identity==1">群众</blockquote>
										<blockquote style="margin:0" v-else-if="scope.row.identity==2">少先队员</blockquote>
										<blockquote style="margin:0" v-else-if="scope.row.identity==3">团员</blockquote>
										<blockquote style="margin:0" v-else-if="scope.row.identity==4">党员</blockquote>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="birthday" label="出生日期" width="100"></el-table-column>
							<el-table-column prop="student_sn" label="国网学籍号" width="100"></el-table-column>
							<el-table-column prop="former_school" label="原学校"></el-table-column>
							<el-table-column prop="nation" label="民族"></el-table-column>
							<el-table-column prop="native_place" label="籍贯"></el-table-column>
							<el-table-column prop="name1" label="监护人1"></el-table-column>
							<el-table-column prop="name2" label="监护人2"></el-table-column>
							<!-- <el-table-column prop="place" label="学生去处"></el-table-column> -->
							<el-table-column prop="remark" label="备注"></el-table-column>
							<!-- <el-table-column v-for="(item, index) in otherTableMenu" :key="index" :prop="item.prop" :label="item.label"></el-table-column> -->
							<el-table-column label="操作" width="80" fixed="right">
								<template slot-scope="scope">
									<el-button @click="VisibleTap(2,scope.row)" type="text" size="small">编辑</el-button>
									<!-- <el-button @click="VisibleTap(3,scope.row)" type="text" size="small">重置密码</el-button> -->
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
							<el-button type="danger" plain size="mini" @click="delGrade">删除</el-button>
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</template>
				<template v-if="tabIndex==1">
					<div class="content" style="padding: 0 30px">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{total}}条数据，
								<span class="btn">选择全部</span>
							</div>
							<div class="u-f-item">
								<el-input placeholder="请输入内容" v-model="sousuo1" size="small" style="margin-right: 10px;">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
								<el-button size="small" @click="VisibleTap(3)">添加人员</el-button>
								<!-- <el-button size="small">办理离校</el-button>
								<el-button size="small">批量重置密码</el-button>
								<el-button size="small">批量更新</el-button>
								<el-button size="small">批量录入</el-button>
								<el-button size="small">导出</el-button> -->
							</div>
						</div>
						<el-table
							:data="qxList"
							height="75%"
							ref="multipleTable"
							tooltip-effect="dark"
							@selection-change="handleSelectionChange"
							style="width: 100%;border: 1px solid #EEEEEE;"
							key="2"
						>
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column label="序号" type="index"  width="150"></el-table-column>
							<el-table-column prop="name" label="姓名"></el-table-column>
							<el-table-column prop="isset" label="表头设置" :filters="[{text: '是', value: '1'}, {text: '否', value: '2'}]"
							:filter-method="filterHandler">
								<template slot-scope="scope">
									<div class="btn" style="background: #e4f1ff;color: #1E92FF;display: inline-block;padding: 5px 25px;">{{scope.row.isset==1?'是':'否'}}</div>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="150">
								<template slot-scope="scope">
									<el-button type="text" size="small">编辑</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
							<el-button type="danger" plain size="mini" @click="delGrade">删除</el-button>
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</template>
			</div>
			<el-dialog :title="VisibleTitle" :visible.sync="addVisible" :width="VisibleWidth" :before-close="handleClose">
				<blockquote style="margin:0" v-if="type==0">
					<div class="header u-f">
						<div class="h-show">
							<div class="title">表头显示</div>
							<div>
								<div class="item">入学学年</div>
								<div class="item">学籍号</div>
								<div class="item">姓名</div>
								<div class="item">性别</div>
								<div class="item">初始密码</div>
								<div class="item">数据来源</div>
							</div>
						</div>
						<div class="h-set">
							<div class="title">字段设置</div>
							<div class="box">
								<el-checkbox-group v-model="checkList">
									<div class="item" v-for="(item, index) in otherTableMenu" :key="index">
										<div class="u-f-item u-f-jsb" @mouseenter="item.isshow = true" @mouseleave="item.isshow = false">
											<el-checkbox :label="item.label" :key="index"></el-checkbox>
											<div class="u-f-item" v-if="item.isshow">
												<i class="el-icon-edit-outline u-f-justify" style="margin-right: 10px"></i>
												<i class="el-icon-delete u-f-justify"></i>
											</div>
										</div>
									</div>
								</el-checkbox-group>
							</div>
						</div>
					</div>
				</blockquote>
				<blockquote style="margin:0" v-else-if="type==1||type==2">
					<div class="edit-box">
						<div class="u-f-item input-box">
							<div class="input-lebal"><span>*</span>姓名</div>
							<el-input v-model="form.realname" placeholder="请输入姓名" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">学籍号</div>
							<el-input v-model="form.student_num" placeholder="请输入学籍号" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal"><span>*</span>性别</div>
							<el-radio-group v-model="form.sex">
								<el-radio-button label="1">男</el-radio-button>
								<el-radio-button label="2">女</el-radio-button>
							</el-radio-group>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">身份证</div>
							<el-input v-model="form.idcard" placeholder="请输入身份证" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">学籍性质</div>
							<el-radio-group v-model="form.stunature_status">
								<el-radio-button label="1">本校</el-radio-button>
								<el-radio-button label="2">挂学籍</el-radio-button>
							</el-radio-group>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">状态</div>
							<el-radio-group v-model="form.status">
								<el-radio-button label="1">在校</el-radio-button>
								<el-radio-button label="2">离校</el-radio-button>
							</el-radio-group>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">身份</div>
							<el-radio-group v-model="form.identity">
								<el-radio-button label="1">群众</el-radio-button>
								<el-radio-button label="2">少先队员</el-radio-button>
								<el-radio-button label="3">团员</el-radio-button>
								<el-radio-button label="4">党员</el-radio-button>
							</el-radio-group>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal"><span>*</span>出生日期</div>
							<el-date-picker type="date" placeholder="选择日期" v-model="form.birthday" value-format="yyyy-MM-dd" style="width: 80%;"></el-date-picker>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">国网学籍号</div>
							<el-input v-model="form.student_sn" placeholder="请输入国网学籍号" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">原学校</div>
							<el-input v-model="form.former_school" placeholder="请输入原学校" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">民族</div>
							<el-input v-model="form.nation" placeholder="请输入民族" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">籍贯</div>
							<el-select v-model="form.native_place_province" placeholder="请选择省" style="width: 39%;margin-right:2%" @change="changePro" key="1">
								<el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-select v-model="form.native_place_city" placeholder="请选择市" style="width: 39%;" key="2">
								<el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">家庭地址</div>
							<el-select v-model="form.province_id" placeholder="请选择省" style="width: 25%;margin-right:2%" @change="changePro2" key="3">
								<el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-select v-model="form.city_id" placeholder="请选择市" style="width: 25%;margin-right:2%" @change="changeCity2" key="4">
								<el-option v-for="item in cityList2" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-select v-model="form.area_id" placeholder="请选择区" style="width: 25%;" key="5">
								<el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-input v-model="form.address" placeholder="请输入家庭地址" style="width: 80%;margin-left: calc(15% + 10px);margin-top: 10px;"></el-input>
						</div>
						<!-- <div class="u-f-item input-box">
							<div class="input-lebal">家庭地址</div>
							<el-input v-model="form.address" placeholder="请输入家庭地址" style="width: 80%;"></el-input>
						</div> -->
						<div class="u-f-item input-box">
							<div class="input-lebal">常用电话</div>
							<el-input v-model="form.phone" placeholder="请输入常用电话" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">监护人1</div>
							<el-select v-model="form.relationship1" placeholder="请选择关系" style="width: 80%;">
								<el-option v-for="item in relationList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">姓名</div>
							<el-input v-model="form.name1" placeholder="请输入姓名" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">电话</div>
							<el-input v-model="form.tel1" placeholder="请输入电话" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">工作单位</div>
							<el-input v-model="form.work_unit1" placeholder="请输入工作单位" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">监护人2</div>
							<el-select v-model="form.relationship2" placeholder="请选择关系" style="width: 80%;">
								<el-option v-for="item in relationList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">姓名</div>
							<el-input v-model="form.name2" placeholder="请输入姓名" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">电话</div>
							<el-input v-model="form.tel2" placeholder="请输入电话" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">工作单位</div>
							<el-input v-model="form.work_unit2	" placeholder="请输入工作单位" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">学生去处</div>
							<el-input v-model="form.place" placeholder="请输入学生去处" style="width: 80%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">备注</div>
							<el-input v-model="form.remark" placeholder="请输入备注" style="width: 80%;"></el-input>
						</div>
					</div>
				</blockquote>
				<blockquote style="margin:0" v-else-if="type==3">
					<div class="edit-box">
						<div class="u-f-item input-box">
							<div class="input-lebal"><span>*</span>选择人员</div>
							<el-select v-model="t_id" multiple placeholder="请选择年级长" style="width: 80%;">
								<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">表头设置</div>
							<el-switch v-model="isset"></el-switch>
						</div>
					</div>
				</blockquote>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeVisible" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		
			<!-- 批量更新、批量录入 -->
			<el-dialog :title="updateVisibleTitle" :visible.sync="updateVisible" width="500px" :before-close="handleClose">
				<div class="update_box">
					<div class="update_item">
						<div>请按照模板格式进行录入</div>
						<div class="u-f-item" style="margin-top: 5px;">
							<!-- <img src="../../../dist/static/image/excel.png" style="width: 18px;margin-right: 3px;" /> -->
							<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
							<a :href="fileUrl">下载模板</a>
						</div>
					</div>
					<div class="u-f" style="margin-top: 20px;">
						<div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">数据录入</div>
						<div>
							<el-button size="mini" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">点击上传</el-button>
							<div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>{{file.name}}
								</div>
								<i class="el-icon-circle-check" style="color: #67C23A;"></i>
								<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
							</div>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeVisible" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="gengxin">确 定</el-button>
				</span>
			</el-dialog>
		</index>	
	</div>
</template>

<script>
import index from '@/components/common/index.vue'
export default {
	components: {
			index
	},
	props: {
		
	},
	data() {
		return {
			loading: false,
			tabMenu: [
				{ name: '学籍管理' },
				{ name: '权限配置' }
			],
			tabIndex: 0,
			condition: 0,
			conditionList: [],
			year: '',
			yearList: [],
			total: 0,
			studentList: [],
			page: 1,
			limit: 10,
			sousuo: '',
			sousuo1:'',
			type: 0,
			ids: [],
			otherTableMenu: [
				{prop: '', label: '学部', isfilter: false, isFix: false},
				{prop: '', label: '邮箱', isfilter: false, isFix: false},
				{prop: '', label: '银行卡号', isfilter: false, isFix: false},
				{prop: '', label: '教工子女', isfilter: false, isFix: false},
				{prop: '', label: '是否就餐', isfilter: false, isFix: false},
				{prop: '', label: '身份证', isfilter: false, isFix: false},
				{prop: '', label: '学籍性质', isfilter: false, isFix: false},
				{prop: '', label: '状态', isfilter: true, isFix: false, filter: [{text: '是', value: '1'}, {text: '否', value: '2'}]},
				{prop: '', label: '身份', isfilter: false, isFix: false},
				{prop: '', label: '出生日期', isfilter: false, isFix: false},
				{prop: '', label: '国网学籍号', isfilter: false, isFix: false},
				{prop: '', label: '原学校', isfilter: false, isFix: false},
				{prop: '', label: '民族', isfilter: false, isFix: false},
				{prop: '', label: '籍贯', isfilter: false, isFix: false},
				{prop: '', label: '家庭地址', isfilter: false, isFix: false},
				{prop: '', label: '常用电话', isfilter: false, isFix: false},
				{prop: '', label: '监护人1', isfilter: false, isFix: false},
				{prop: '', label: '监护人2', isfilter: false, isFix: false},
				{prop: '', label: '学生去处', isfilter: false, isFix: false},
				{prop: '', label: '备注', isfilter: false, isFix: false},
			],
			VisibleTitle: '表头设置',
			addVisible: false,
			VisibleWidth: '500px',
			checkList: [],
			userList: [],
			name: '',
			sex: '男',
			form: {
				realname: '',
				student_num: '',
				sex: 1,
				idcard: '',
				stunature_status: 1,
				status: 1,
				identity: 1,
				birthday: '',
				student_sn: '',
				former_school: '',
				nation: '汉族',
				native_place_province: '',
				native_place_city: '',
				province_id: '',
				city_id: '',
				area_id: '',
				address: '',
				phone: '',
				relationship1: '',
				name1: '',
				tel1: '',
				work_unit1: '',
				relationship2: '',
				name2: '',
				tel2: '',
				work_unit2: '',
				place: '',
				remark: ''
			},
			relationList: [
				{id: 1, name: '父亲'},
				{id: 2, name: '母亲'},
				{id: 3, name: '爷爷'},
				{id: 4, name: '奶奶'},
				{id: 5, name: '外公'},
				{id: 6, name: '外婆'},
				{id: 7, name: '哥哥'},
				{id: 8, name: '姐姐'},
				{id: 9, name: '其他'},
			],
			provinceList: [],
			cityList: [],
			provinceList2: [],
			cityList2: [],
			areaList: [],
			t_id: [],
			isset: false,
			qxList: [{name: '刘原', isset: 1},{name: '林易', isset: 2}],
			updateVisibleTitle:'',
			updateVisible:false,
			file: '',
			fileUrl:'',
			type:'',
			fsex:'',
			fnature:'',
			fstatus:'',
			fidentity:''
		};
	},
	created() {},
	mounted() {
		this.getYearList();
		this.getProList();
	},
	methods: {
		getFile(e){
			if(this.$refs.filElem.files.length){
				this.file = this.$refs.filElem.files[0]
			}
		},
		delFile(){
			this.file = '';
		},
		openUpdate(type){
			this.file = '';
			this.type = type;
			if(type==1){
				this.updateVisibleTitle = '批量更新'
				let data = {
					ent_year:this.year
				}
				this.$api.student.updateImportTempStu(data).then(res=>{
					if(res.data.code==1){
						this.fileUrl = res.data.data.url;
						this.updateVisible = true
					}
				})
			}else if(type==2){
				this.updateVisibleTitle = '批量录入'
				this.$api.student.ImportTempStu({}).then(res=>{
					if(res.data.code==1){
						this.fileUrl = res.data.data.url;
						this.updateVisible = true
					}
				})
			}
		},
		gengxin(){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			formData.append("ent_year", this.year);
			if(this.type==1){
				this.$api.student.updateImportStu(formData).then(res=>{
					if(res.data.code==1){
						this.$message.success('更新成功');
						this.studentData()
						this.file = '';
						this.updateVisible = false;
					}else{
						this.$message.error(res.data.msg);
					}
				})
			}else if(this.type==2){
				this.$api.student.importStu(formData).then(res=>{
					if(res.data.code==1){
						this.$message.success('导入成功');
						this.studentData()
						this.file = '';
						this.updateVisible = false;
					}else{
						this.$message.error(res.data.msg);
					}
				})
			}
			
		},
		// 办理离校
		leaveStu(){
			if(this.ids.length){
				this.$confirm('是否确定办理离校？','提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(()=>{
					this.$api.student.leaveStu({
						ids:this.ids
					}).then(res=>{
						if(res.data.code==1){
							this.$message.success('办理成功')
							this.studentData()
						}else{
							this.$message.error(res.data.msg)
						}
					})
				})
			}else{
				this.$message.error('请选择数据')
			}
		},
		selectAll(){
			let self = this;
			this.studentList.forEach(row => {
				self.$refs.multipleTable1.toggleRowSelection(row, true);
			});
		},
		twacherTap(index) {
			this.tabIndex = index;
		},
		// 学年列表
		getYearList(){
			this.$api.setting.getSemesterList().then(res=>{
				if(res.data.code==1){
					this.yearList = res.data.data;
					if (res.data.data.length) {
						if (this.yearList.filter(item => item.is_default == 2).length > 0) {
							this.year = this.yearList.filter(item => item.is_default == 2)[0].name;
						} else {
							this.year = this.yearList[0].name;
						}
					} else {
						this.year = '';
					}
					this.studentData()
				}
			})
		},
		changeYear(e){
			this.year = e;
			this.page = 1;
			this.studentList = [];
			this.studentData()
		},
		// 省列表
		getProList(){
			this.$api.login.getAreaList().then(res=>{
				if(res.data.code==1){
					this.provinceList = res.data.data;
					this.cityList = [];
					this.cityList2 = [];
					if(this.form.native_place_province){
						this.getCityList(this.form.native_place_province)
					}
					if(this.form.province_id){
						this.getCityList(this.form.province_id, true)
					}
				}
			})
		},
		// 市列表
		getCityList(id, type=false){
			this.$api.login.getAreaList({id: id}).then(res=>{
				if(res.data.code==1){
					if(type){
						this.cityList2 = res.data.data;
						this.areaList = [];
						if(this.form.city_id){
							this.getAreaList(this.form.city_id, true)
						}
					}else{
						this.cityList = res.data.data;
					}
				}
			})
		},
		// 区列表
		getAreaList(id, type=false){
			this.$api.login.getAreaList({id: id}).then(res=>{
				if(res.data.code==1){
					if(type) this.areaList = res.data.data
				}
			})
		},
		changePro(e){
			this.form.native_place_city = '';
			this.getCityList(e)
		},
		changeCity(e){
			this.getAreaList(e)
		},
		changePro2(e){
			this.form.city_id = '';
			this.form.area_id = '';
			this.getCityList(e, true)
		},
		changeCity2(e){
			this.form.area_id = '';
			this.getAreaList(e, true)
		},
		// 关闭弹窗
		handleClose (done) {
			this.initialize();
			done()
		},
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.studentData()
		},
		filterTag(value, row) {
			return row.tag == value;
		},
		filterHandler(value, row, column) {
			const property = column['property'];
			if(property=='sex'){this.fsex = value}
			if(property=='stunature_status'){this.fnature = value}
			if(property=='status'){this.fstatus = value}
			if(property=='identity'){this.fidentity = value}
			return row[property] == value;
		},
		sousuoList(){
			this.page = 1;
			this.studentData()
		},
		studentData(){
			let data = {
				page: this.page,
				limit: this.limit,
				ent_year: this.year
			}
			if(this.sousuo){data.keyword = this.sousuo}
			this.$api.student.stuList(data).then(res => {
				if (res.data.code == 1) {
					let list = res.data.data.rows;
					this.total = res.data.data.total;
					this.studentList = list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 弹窗
		VisibleTap(type,item){
			this.type = type;
			this.VisibleWidth = '500px';
			if(type==0){
				this.VisibleTitle = '表头设置';
				this.VisibleWidth = '800px';
				for(let i=0;i<this.otherTableMenu.length;i++){
					this.otherTableMenu[i].isshow = false
				}
			}else if(type==1){
				this.VisibleTitle = '新建学籍';
			}else if(type==2){
				this.VisibleTitle = '编辑学籍';
				this.id = item.id;
				this.$api.student.detailStu({id:this.id}).then(res=>{
					if(res.data.code==1){
						if(res.data.data.native_place_province){
							this.getProList()
						}
						if(res.data.data.province_id){
							this.getProList()
						}
						this.form = {
							realname: res.data.data.realname,
							student_num: res.data.data.student_num,
							sex: res.data.data.sex,
							idcard: res.data.data.idcard,
							stunature_status: res.data.data.stunature_status,
							status: res.data.data.status,
							identity: res.data.data.identity,
							birthday: res.data.data.birthday,
							student_sn: res.data.data.student_sn,
							former_school: res.data.data.former_school,
							nation: res.data.data.nation,
							native_place_province: res.data.data.native_place_province>0?Number(res.data.data.native_place_province):'',
							native_place_city: res.data.data.native_place_city>0?Number(res.data.data.native_place_city):'',
							province_id: res.data.data.province_id>0?res.data.data.province_id:'',
							city_id: res.data.data.city_id>0?res.data.data.city_id:'',
							area_id: res.data.data.area_id>0?res.data.data.area_id:'',
							address: res.data.data.address,
							phone: res.data.data.phone,
							relationship1: res.data.data.relationship1,
							name1: res.data.data.name1,
							tel1: res.data.data.tel1,
							work_unit1: res.data.data.work_unit1,
							relationship2: res.data.data.relationship2,
							name2: res.data.data.name2,
							tel2: res.data.data.tel2,
							work_unit2: res.data.data.work_unit2,
							place: res.data.data.place,
							remark: res.data.data.remark
						}
					}
				})
				
			}else if(type==3){
				console.log(item)
				this.VisibleTitle = '添加人员';
				
			}
			this.addVisible = true;
		},
		// 学籍导出
		exportStu(){
			let data = {
				ent_year: this.year
			}
			if(this.ids.length){data.ids = this.ids}
			if(this.fsex){data.sex = this.fsex}
			if(this.fnature){data.nature = this.fnature}
			if(this.fstatus){data.status = this.fstatus}
			if(this.fidentity){data.identity = this.fidentity}
			if(this.sousuo){data.keyword = this.sousuo}
			this.$api.student.exportStu(data).then(res=>{
				if(res.data.code==1){
					location.href = res.data.data.url;
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		// 关闭弹窗
		closeVisible(){
			this.initialize()
			this.addVisible = false;
			this.updateVisible = false;
		},
		initialize(){
			this.form = {
				realname: '',
				student_num: '',
				sex: 1,
				idcard: '',
				stunature_status: 1,
				status: 1,
				identity: 1,
				birthday: '',
				student_sn: '',
				former_school: '',
				nation: '汉族',
				native_place_province: '',
				native_place_city: '',
				province_id: '',
				city_id: '',
				area_id: '',
				address: '',
				phone: '',
				relationship1: '',
				name1: '',
				tel1: '',
				work_unit1: '',
				relationship2: '',
				name2: '',
				tel2: '',
				work_unit2: '',
				place: '',
				remark: ''
			}
		},
		// 添加、编辑年级
		submit(){
			if(this.type==0){
				// this.addGrade()
			}else if(this.type==1){
				this.addGrade()
			}else if(this.type==2){
				this.editGrade()
			}
		},
		// 添加学籍
		addGrade(){
			this.form.data_source = 1;
			this.form.ent_year = this.year;
			console.log(this.form)
			this.$api.student.addStu(this.form).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.studentData();
					this.addVisible = false;
					this.initialize()
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 编辑年级
		editGrade(){
			this.form.data_source = 1;
			this.form.ent_year = this.year;
			this.form.id = this.id;
			this.$api.student.editStu(this.form).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('修改成功');
					this.studentData();
					this.addVisible = false;
					this.initialize()
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 删除年级
		delGrade(){
			let _this = this;
			if(this.ids.length){
				this.$confirm('确定要删除？','提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(()=>{
					this.$api.student.delStu({
						id:this.ids
					}).then(res=>{
						if (res.data.code == 1) {
							_this.$message.success('删除成功');
							_this.studentData();
						} else {
							_this.$message.error(res.data.msg);
						}
					})
				})
			}else{
				this.$message.error('请选择要删除的学籍')
			}
		},
	}
};
</script>

<style lang="scss" scope>
#student{
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		background-color: #ffffff;
		height: 78vh;
		margin-top: 20px;
		padding: 0 30px;
		.title {
			padding: 15px 0;
			.btn {
				background-color: #f3f3f3;
				font-size: 12px;
				padding: 5px 10px;
				color: #555555;
				cursor: pointer;
			}
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn{
			padding: 5px;
			text-align: center;
		}
	}
	.el-dialog__body {
		padding: 20px 20px 50px;
		max-height: 500px;
		overflow-y: auto;
		.header {
			.h-show{
				width: 30%;
				padding-right: 30px;
				border-right: 1px solid #aaa;
				margin-right: 30px;
				>div:nth-child(2){
					overflow-y: auto;
					max-height: 500px;
					.item{
						background-color: #F3F3F3;
						border-radius: 3px;
						padding: 10px;
						margin-top: 10px;
					}
				}

			}
			.h-set{
				width: calc(70% - 100px);
				.box{
					padding-top: 10px;
					overflow-y: auto;
					max-height: 500px;
					.item{
						padding: 15px 15px 15px 0;
						border-top: 1px solid #eee;
					}
				}
			}
			.title{
				font-size: 17px;
				font-weight: bold;
			}
		}
		.edit-box {
			.input-box {
				margin-top: 35px;
				flex-wrap: wrap;
				.input-lebal {
					width: 15%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
		}
		.update_box {
			padding: 40px 20px;
			.update_item {
				background-color: #f6f8fb;
				padding: 20px;
				a {
					color: #007aff;
					text-decoration: none;
				}
			}
			.upload-file{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.file_box{
				padding: 5px;
				.el-icon-circle-check{
					display: block;
				}
				.el-icon-circle-close{
					display: none;
				}
			}
			.file_box:hover{
				color: #007AFF;
				background-color: #F6F8FB;
				.el-icon-circle-check{
					display: none;
				}
				.el-icon-circle-close{
					display: block;
				}
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
	
</style>
